<template>
    <div v-show="loaded">
        <div
            @contextmenu.prevent="disabled ? null : handleCustomContext($event, item)"
            @click.prevent="disabled ? null : handleGalleryItemClick($event, item)"
            @dblclick="disabled ? null : handleGalleryItemDblClick($event, item)"
            @touchstart="disabled ? null : handleTouchStart($event, item)"
            @touchend="disabled ? null : handleTouchEnd($event, item)"
            @touchmove="disabled ? null : handleTouchMove($event, item)"
            v-intersect="
                (entries, observer, isIntersecting) => handleIntersect(entries, observer, isIntersecting, item, index)
            "
            class="gallery-img-group"
        >
            <div class="selected-overlay"></div>
            <div class="gallery-img-container" :id="`photo-container-${item.id}`">
                <div
                    v-if="brokenImgOverlay"
                    class="gallery-photo broken-img-overlay d-flex justify-center align-center flex-column"
                >
                    <font-awesome-icon icon="fa-solid fa-image-slash" style="font-size: 2rem"></font-awesome-icon>
                    <span class="text-center text-caption mt-5 px-6">This image is could not be loaded</span>
                </div>

                <img
                    v-if="(item.mediaType === 0 || item.mediaType == 2) && !brokenImgOverlay"
                    @error="handleImgError(item)"
                    @load="setImageDimensions(item.id)"
                    :src="handleFileSrc(item.url)"
                    :id="`photo-${item.id}`"
                    height="250px"
                    class="gallery-photo"
                />

                <div :key="posterRefreshKey" v-if="item.mediaType == 1">
                    <img
                        v-if="showPoster"
                        :key="item.id + '-' + item.refreshKey"
                        :src="handleThumbnail(item.url)"
                        @error="handlePosterError(item)"
                        @load="handlePosterLoad(item)"
                        height="250px"
                        class="gallery-photo"
                    />
                    <video
                        v-if="!showPoster"
                        class="gallery-photo gallery-video"
                        :ref="`video-${index}`"
                        :id="`video-${item.id}`"
                        :key="item.id + '-' + item.refreshKey"
                        :src="handleFileSrc(item.url)"
                        height="250px"
                    ></video>
                </div>

                <font-awesome-icon
                    @click.stop="disabled ? null : $emit('toggle-mute')"
                    v-if="item.mediaType == 1"
                    class="mute-status-icon"
                    :icon="item.mute ? 'fa-regular fa-volume-xmark' : 'fa-regular fa-volume'"
                ></font-awesome-icon>

                <div class="top-left-group">
                    <v-chip v-if="item.mediaType == 1" class="px-2 video-tag" x-small dark color="#ab0658"
                        >VIDEO</v-chip
                    >
                </div>
                <div class="top-right-group">
                    <v-btn
                        v-if="!disabled"
                        @click.stop="disabled ? null : deleteStep1(item)"
                        :disabled="item.loading"
                        class="action-btn delete-action-btn"
                        color="error"
                        x-small
                        fab
                    >
                        <font-awesome-icon style="font-size: 0.8rem" icon="fa-regular fa-trash-can"></font-awesome-icon>
                    </v-btn>

                    <v-btn
                        v-if="!disabled"
                        @click.stop="handleCustomContext($event, item)"
                        :disabled="item.loading"
                        class="action-btn"
                        x-small
                        fab
                    >
                        <font-awesome-icon style="font-size: 0.7rem" icon="fa-regular fa-ellipsis"></font-awesome-icon>
                    </v-btn>
                </div>

                <div class="bottom-right-group">
                    <!-- START: rotate button -->
                    <v-btn
                        v-if="item.mediaType != 1 && !disabled"
                        @click.stop="disabled ? null : rotateHandler(item)"
                        :disabled="loading"
                        class="action-btn rotate-action-btn"
                        x-small
                        fab
                    >
                        <font-awesome-icon
                            style="font-size: 0.8rem"
                            icon="fa-solid fa-rotate-right"
                        ></font-awesome-icon>
                    </v-btn>
                    <!-- END: rotate button -->
                </div>

                <div class="bottom-left-group">
                    <v-progress-circular
                        v-if="loading"
                        color="primary"
                        class="action-btn corner-loader"
                        v-model="progress"
                    ></v-progress-circular>
                </div>

                <div v-if="showSyncLabel" class="item-sync-overlay">
                    <v-chip v-if="item.isDeleted" small label color="error"> Deleted</v-chip>
                    <v-chip v-else-if="item.isAdded" small label color="success"> Added</v-chip>
                    <v-chip v-else-if="item.isReordered" small label color="info"> Reordered</v-chip>
                </div>

                <div v-if="item.removeFromRender" class="removed-from-render-warning">
                    <div class="photo-details">
                        <div class="filename-inner">
                            <span class="filename__base text-truncate text-center flex-grow-1"
                                >Removed from render</span
                            >
                        </div>
                    </div>
                </div>

                <div v-if="item.name && !thumbnailOnly" class="photo-details">
                    <div class="filename-inner">
                        <strong class="filename__base text-truncate">{{ handleBase(item.name) }}</strong>
                        <strong class="filename__extension">{{ handleExtension(item.name) }}</strong>
                    </div>
                </div>

                <div v-if="galleryType == 'out-of-sync' && item.changeRecords.length > 0" class="photo-details">
                    <div class="filename-inner">
                        <span class="filename__base text-truncate"
                            >User:
                            {{ item.changeRecords[0].userEmail ? item.changeRecords[0].userEmail : 'Unknown' }}</span
                        >
                    </div>
                </div>

                <!-- <v-alert dense class="m-0 text-truncate" type="error">Removed</v-alert> -->
            </div>

            <div class="d-flex flex-column" v-if="devMode">
                <div class="age-score">
                    <span>Id: {{ item.id }}</span>
                    <span>Order: {{ item.order }}</span>
                    <span>Age: {{ item.age }}</span>
                    <span>Score: {{ item.qualityScore }}</span>
                    <span>Source: {{ uploadSources[item.uploadSource].label }}</span>
                    <span>Name: {{ item.uploadUserName }}</span>
                    <span>Rel: {{ item.uploadUserRelationship }}</span>
                    <v-btn class="text-caption" @click.stop="copyToClipboard(item.perceptualHash)"
                        >Hash: {{ item.perceptualHash }}</v-btn
                    >
                    <span>fBits: {{ item.lshFirstBits }}</span>
                    <span>mBits: {{ item.lshMiddleBits }}</span>
                    <span>rBits: {{ item.lshRandomBits }}</span>
                </div>

                <!-- all keywords -->
                <div class="keywords">
                    <div class="top-keywords">
                        <span style="font-size: 0.8rem; display: block">Top Keywords:</span>
                        <v-chip class="mr-1" dark x-small v-if="item.event" color="#975DBE">{{ item.event }}</v-chip>
                        <v-chip class="mr-1" dark x-small v-if="item.place" color="#009688">{{ item.place }}</v-chip>
                        <v-chip class="mr-1" dark x-small v-if="item.hobby" color="#374550">{{ item.hobby }}</v-chip>
                    </div>
                    <span style="font-size: 0.8rem; display: block">All Keywords:</span>
                    <v-chip class="mr-1" v-for="kw in item.imageKeywords" x-small color="orange" dark>{{
                        kw.name
                    }}</v-chip>
                </div>
            </div>
        </div>
        <!-- <div class="text-center" style="width: 100%; min-height: 22px; font-size: 0.8rem">
            <span v-if="!disabled" class="hidden-text">
                <font-awesome-icon icon="fa-regular fa-arrow-left"></font-awesome-icon>
                Drag to re-arrange
                <font-awesome-icon icon="fa-regular fa-arrow-right"></font-awesome-icon>
            </span>
        </div> -->
    </div>
</template>
<script>
import { uploadSources } from '@/constants';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            uploadSources,
            loading: false,
            touchTimeout: null,
            isScrolling: false,
            touchStartX: 0,
            touchStartY: 0,
            photoRotation: 0,
            brokenImgOverlay: false,
            currentRotation: 0,
            nextImage: '',
            progress: 0,
            posterReloadCount: 0,
            loaded: false,
            showPoster: true,
            posterRefreshKey: 0,
        };
    },
    components: { CustomTooltip },
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            requried: true,
        },
        devMode: {
            type: Boolean,
            default: false,
        },
        eventId: {
            type: Number,
            required: true,
        },
        token: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showSyncLabel: {
            type: Boolean,
            default: false,
        },
        maxIndex: {
            type: Number,
            required: true,
        },
        galleryType: {
            type: String,
            required: false,
        },
        thumbnailOnly: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        token() {
            this.createAxiosInstance();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),

        setImageDimensions(itemId) {
            const imgElement = document.getElementById(`photo-${itemId}`);

            if (!imgElement) return;

            const originalWidth = imgElement.naturalWidth;
            const originalHeight = imgElement.naturalHeight;

            const parentElement = document.getElementById(`photo-${itemId}`);

            if (!parentElement) return;

            parentElement.style.aspectRatio = `${originalWidth}/${originalHeight}`;
            this.loaded = true;
        },
        copyToClipboard(val) {
            navigator.clipboard.writeText(val);
            this.showSnackbar({ message: 'Copied hash to Clipboard' });
        },
        rotateHandler(item) {
            if (!this.disabled) {
                this.$emit('rotate', item);
            }
        },
        handleIntersect(entries, observer, isIntersecting, item, index) {
            if (!isIntersecting || !this.loaded) {
                return;
            }

            if (index == 0) {
                this.$emit('gallery-top-intersect');
                return;
            }
            if (index == this.maxIndex) {
                this.$emit('gallery-bottom-intersect');
                return;
            }
        },
        handleImgError(item) {
            this.loaded = true;
            this.brokenImgOverlay = true;
        },
        rotateImage(deg) {
            if (!this.disabled) {
                var photo = this.item;
                return new Promise(async (resolve, reject) => {
                    try {
                        this.loading = true;
                        this.currentRotation = deg;
                        const img = new Image();
                        img.src = photo.url;
                        img.crossOrigin = 'anonymous';

                        img.onload = async () => {
                            const canvas = this.createCanvas(img, deg);

                            const file = await this.convertToFile(canvas, photo.name);

                            const SAS_INFO = await this.getUploadUrl(photo.name);

                            await this.uploadToEndpoint(file, SAS_INFO.sas, ev => {
                                const percentage = (ev.loaded / ev.total) * 100;
                                this.progress = percentage;
                            });

                            const replacement = await this.replacePhoto(photo.id, SAS_INFO.fileName, deg, true);

                            try {
                                await this.preloadSourceReplace(photo, replacement.url);
                            } catch (err) {
                                console.log('Error preloading image', err);
                            }

                            this.loading = false;
                            this.progress = 0;
                            resolve();
                        };

                        img.onerror = () => {
                            reject(new Error('Image failed to load'));
                        };
                    } catch (err) {
                        reject(err);
                    }
                });
            }
        },
        createCanvas(img, deg) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (deg % 180 === 0) {
                canvas.width = img.width;
                canvas.height = img.height;
            } else {
                canvas.width = img.height;
                canvas.height = img.width;
            }

            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((deg * Math.PI) / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);

            return canvas;
        },
        async convertToFile(canvas, name) {
            return new Promise(resolve => {
                canvas.toBlob(
                    blob => {
                        const file = new File([blob], name);
                        resolve(file);
                    },
                    'image/jpeg',
                    0.9,
                );
            });
        },
        async getUploadUrl(name) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/sas/${this.eventId}/${name}`)
                .then(response => {
                    // log('==== getUploadUrl() called ====', 'silver');

                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async uploadToEndpoint(file, endpoint, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.addEventListener('error', error => {});
                reader.addEventListener('load', async () => {
                    const config = {
                        onUploadProgress: onProgressUpdate,
                    };
                    try {
                        this.axios
                            .create({
                                headers: {
                                    'Content-Type': 'image/jpg',
                                    'x-ms-blob-type': 'BlockBlob',
                                },
                            })
                            .put(endpoint, reader.result, config)
                            .then(response => {
                                resolve('done');
                            });
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        replacePhoto(id, fileName) {
            if (!this.disabled) {
                let data = {
                    fileName: fileName,
                };

                return this.axios
                    .create({
                        headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'application/json' },
                    })
                    .put(process.env.VUE_APP_API + `/TributeVideoPhoto/replace-photo/${id}?`, data)
                    .then(res => {
                        return res.data;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            }
        },
        preloadSourceReplace(photo, newUrl) {
            return new Promise((resolve, reject) => {
                const tempImage = new Image(250, 250);

                tempImage.onload = () => {
                    this.resetRotation(photo);
                    photo.url = newUrl;
                    resolve();
                };

                // Start preloading the new image in the background
                tempImage.src = this.handleFileSrc(newUrl);

                tempImage.onerror = () => {
                    //On preload error, set image source to azure link instead of imageKit transformed image
                    photo.url = newUrl;
                    reject(new Error('Image could not be preloaded'));
                };
            });
        },
        resetRotation(photo) {
            photo.currentRotation = 0;
            const el = document.getElementById(`photo-${photo.id}`);
            if (el) {
                el.style.transition = 'none';
                el.style.transform = 'none';
            }
        },
        animateRotation(photo, deg, time, elementId = `#photo-${photo.id}`) {
            if (!this.disabled) {
                var el = null;
                el = document.querySelector(elementId);

                if (el) {
                    el.style.transition = `transform ${time}s ease-in-out`;
                    el.style.transform = `rotate(${deg}deg)`;
                }
            }
        },
        deleteStep1(item) {
            if (!this.disabled) {
                this.$emit('delete', item);
            }
        },
        handleTouchStart(evt, item) {
            if (!this.disabled) {
                this.isScrolling = false;
                this.touchStartX = evt.touches[0].clientX;
                this.touchStartY = evt.touches[0].clientY;
            }
        },
        handleTouchEnd(evt, item) {
            if (!this.disabled) {
                if (!this.isScrolling) {
                    this.handleGalleryItemClick(evt, item);
                }
            }
        },
        handleTouchMove(evt, item) {
            if (!this.disabled) {
                const touchEndX = evt.touches[0].clientX;
                const touchEndY = evt.touches[0].clientY;

                if (Math.abs(touchEndX - this.touchStartX) > 10 || Math.abs(touchEndY - this.touchStartY) > 10) {
                    this.isScrolling = true;
                }
            }
        },
        handleGalleryItemClick(evt, item) {
            if (!this.disabled) {
                let data = { evt: evt, item: item };
                this.$emit('item-click', data);
            }
        },
        handleGalleryItemDblClick(evt, item) {
            if (!this.disabled) {
                let data = { evt: evt, item: item };
                this.$emit('item-dblclick', data);
            }
        },
        handleCustomContext(evt, item) {
            if (!this.disabled) {
                let data = { evt: evt, item: item };
                this.$emit('custom-context', data);
            }
        },
        handleBase(filename) {
            var splitList = filename.split('.');
            splitList.splice([splitList.length - 1], 1);
            return splitList.join(' ');
        },
        handleExtension(filename) {
            var splitList = filename.split('.');
            return '.' + splitList[splitList.length - 1];
        },
        handleFileSrc(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations
            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                let imgPath = this.extractFilePath(url);

                return `${process.env.VUE_APP_IMG_KIT_BASE}tr:h-200/${imgPath}`;
            }

            return url;
        },
        handleThumbnail(url) {
            if (url) {
                let filePath = this.extractFilePath(url);

                return `${process.env.VUE_APP_IMG_KIT_BASE}${filePath}/ik-thumbnail.jpg`;
            }
        },
        handlePosterError(item) {
            this.showPoster = false;
            this.posterRefreshKey++;
            this.loaded = true;
        },

        handlePosterLoad(item) {
            this.loaded = true;
        },
        extractFilePath(url) {
            let filePath = url;

            if (url.includes('/tribute-photos/')) {
                filePath = url.split('/tribute-photos/')[1];
            } else if (url.includes('/original-tribute-photos/')) {
                filePath = url.split('/original-tribute-photos/')[1];
            }

            return filePath;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async created() {
        this.createAxiosInstance();
        this.nextImage = this.item.url;
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
.item-sync-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
}
.out-of-sync-slide-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.572);
}

.photo-details {
    display: block;
    min-width: 0;
    font-size: 0.8rem;
    width: 100%;
    max-width: 100%;
    height: 1.5rem;
    flex-shrink: 0;
    position: relative;
}

.filename-inner {
    position: absolute;
    display: flex;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    padding: 2px;
    max-width: 100%;
}

.filename__base {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.filename__extension {
    white-space: nowrap;
    flex-shrink: 0;
}

.hidden {
    opacity: 0;
}

.gallery-photo {
    cursor: pointer;
    max-height: 250px;
    object-fit: contain;
    height: 250px;
    z-index: 1;
}

.gallery-video {
    // object-fit: cover;
}
.gallery-img-container {
    position: relative;
    max-width: 100%;
    height: 100%;
    width: fit-content;

    .low-res {
        filter: blur(15px);
    }
}

.edit-close-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 5;
}
.edit-rotate-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 5;
}

.top-right-group {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 4px;
}

.bottom-right-group {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 4px;
}

.bottom-left-group {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    gap: 4px;
}

.top-left-group {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    gap: 4px;
}

.action-btn {
    opacity: 0;
    z-index: 2;
    height: 20px !important;
    width: 20px !important;
}

.action-btn:hover {
    opacity: 1 !important;
}

.gallery-img-container:hover .action-btn {
    opacity: 0.6;
}

.delete-action {
    color: #ff5252 !important;
    transition: 0.4s;
}

.select-photo-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    height: 20px !important;
    width: 20px !important;
}

.keywords {
    height: 150px;
    overflow-y: auto;
    background-color: #f8f8f8;
    margin-top: 5px;
    padding: 5px;
    width: fit-content;
    flex-shrink: 1;
    width: 400px;
}
.age-score {
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5px;
    padding: 5px;
    font-size: 0.9rem;
    width: 400px;
}
.gallery-img-group {
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    max-height: 100%;
    padding: 2px;

    cursor: pointer;
}

.gallery-img-group:hover {
    border: 2px dashed #d3d3d3;
    background-color: #f8f8f8;
}

.mute-status-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 2rem;
    color: white;
}

.selected-overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgb(24, 118, 242, 0.5);
    z-index: 2;
}

.sortable-selected {
    // .gallery-img-group {
    //     border: 2px solid transparent !important;
    //     background-color: #1876f232 !important;
    // }

    .selected-overlay {
        display: block;
    }
}

.broken-img-overlay {
    background-color: #f1f4f7;
    width: 250px;
    height: 250px;
    max-height: 250px;
}

.hidden-text {
    opacity: 0;
    transition-duration: 0.3s;
}
.grid-item:hover .hidden-text {
    opacity: 1;
}

.removed-from-render-warning {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ff5252;
    color: white;
    padding: 4px;
}
</style>
