import ApiService from './api.service';

const TributeVideoService = token => {
    const api = ApiService.createInstance(token);

    return {
        getTributeVideoByEvent(eventId) {
            return api.get(`/TributeVideo/get-tribute-by-event/${eventId}`);
        },
        submitRender(tributeVideoId) {
            let data = {
                tributeId: tributeVideoId,
            };

            return api.post(`/TributeVideoRender/`, data);
        },
    };
};

export default TributeVideoService;
